/**
 * @generated SignedSource<<1f6862b5a1e84b8b7088bb71ad663656>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSbSharedItemTracking_viewer$data = {
  readonly regionalInfo?: ReadonlyArray<{
    readonly regionsByZipCode: ReadonlyArray<{
      readonly displayName: string | null;
    } | null> | null;
  } | null> | null;
  readonly " $fragmentType": "useSbSharedItemTracking_viewer";
};
export type useSbSharedItemTracking_viewer$key = {
  readonly " $data"?: useSbSharedItemTracking_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSbSharedItemTracking_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "fetchRegionalInfo"
    },
    {
      "kind": "RootArgument",
      "name": "userCountryCode"
    },
    {
      "kind": "RootArgument",
      "name": "userZipCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useSbSharedItemTracking_viewer",
  "selections": [
    {
      "condition": "fetchRegionalInfo",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "countryCode",
              "variableName": "userCountryCode"
            },
            {
              "kind": "Variable",
              "name": "zipCode",
              "variableName": "userZipCode"
            }
          ],
          "concreteType": "RegionalInfo",
          "kind": "LinkedField",
          "name": "regionalInfo",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RegionsByZipCode",
              "kind": "LinkedField",
              "name": "regionsByZipCode",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "3e1573835ad373238fa2952e3e8ba718";

export default node;
