import { type AdData } from './sbAdMap';

const AD_VERTICALS_TRADE: AdData = {
    productTile: [
        {
            adId: '/5391766/Featured_Item_Banner_Trade_1',
            elementId: 'trade-1x1-ad-1',
            width: 331,
            height: 419,
        },
        {
            adId: '/5391766/Featured_Item_Banner_Trade_2',
            elementId: 'trade-1x1-ad-2',
            width: 331,
            height: 419,
        },
        {
            adId: '/5391766/Featured_Item_Banner_Trade_3',
            elementId: 'trade-1x1-ad-3',
            width: 331,
            height: 419,
        },
        {
            adId: '/5391766/Featured_Item_Banner_Trade_4',
            elementId: 'trade-1x1-ad-4',
            width: 331,
            height: 419,
        },
    ],
};

export const sbTradeAdMap: { [n: string]: AdData } = {
    furniture: AD_VERTICALS_TRADE,
    art: AD_VERTICALS_TRADE,
    jewelery: AD_VERTICALS_TRADE,
    fashion: AD_VERTICALS_TRADE,
};
