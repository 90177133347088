/**
 * @generated SignedSource<<2577e1e058f26b913ee0b901bb4b3eb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ecommerceTracking_item$data = {
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ecommerceTrackingProductData_item" | "ecommerceTrackingUserProductData_item">;
  readonly " $fragmentType": "ecommerceTracking_item";
};
export type ecommerceTracking_item$key = {
  readonly " $data"?: ecommerceTracking_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ecommerceTracking_item">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ecommerceTracking_item"
};

(node as any).hash = "c437031cf40efaf17311a8aa2ef29ca2";

export default node;
