import { TRADE, CONSUMER } from 'dibs-buyer-layout/exports/userTypeEnums';
import { perfFlags, getPerfQp } from 'dibs-buyer-layout/exports/perfFlags';
import { PAGE_SIZE_OPTIONS } from '../constants/sbConstants';
import {
    sbAdMap,
    sbAdExcludeMap,
    TILE_AD_KEY,
    BILLBOARD_AD_KEY,
    type AdKey,
    type AdProductTile,
    type AdBillboard,
    type AdSkyscraper,
    type AdData,
} from '../constants/sbAdMap';
import { sbTradeAdMap } from '../constants/sbTradeAdMap';

/* Constants */
import {
    QP_PAGE_SIZE_VALUE_DEFAULT,
    QP_PAGE_SIZE_VALUE_MAX,
} from '../constants/queryParamConstants';
import { type EnablerAccessor } from 'dibs-buyer-layout/exports/EnablerAccessor';

type UserType = typeof TRADE | typeof CONSUMER;

const CONSUMER_AD_KEYS = Object.keys(sbAdMap);
const TRADE_AD_KEYS = Object.keys(sbTradeAdMap);

export const adLimitMap = PAGE_SIZE_OPTIONS.reduce<{ [n: number]: number }>(
    (map, { size, adLimit }) => ({
        ...map,
        [size]: adLimit,
    }),
    {}
);

const HOLIDAY_COLLECTION_SEGMENT = 'holiday-gift-guide' as const;

const getUriRefSegments = (uriRef: string): string[] =>
    (uriRef || '')
        .split('?')[0]
        .split('/')
        .filter(segment => segment !== '');

const getAdData = ({
    uriRef,
    adKey,
    userType,
}: {
    uriRef: string;
    adKey: AdKey;
    userType: UserType;
}): AdData | null => {
    let segments = getUriRefSegments(uriRef);
    const lastSegment = segments[segments.length - 1];

    // holiday collection tile ads should show only when no filters applied
    if (
        adKey === TILE_AD_KEY &&
        segments.includes(HOLIDAY_COLLECTION_SEGMENT) &&
        !lastSegment.includes(HOLIDAY_COLLECTION_SEGMENT)
    ) {
        segments = segments.filter(segment => segment !== HOLIDAY_COLLECTION_SEGMENT);
    }

    // run exclusion check: if a segment is present in the exclusion map, check the
    // adKey.  if they both match, return null
    if (
        segments.find(segment => sbAdExcludeMap[segment] && sbAdExcludeMap[segment].includes(adKey))
    ) {
        return null;
    }

    // if trade, use the trade segments before the consumer segments
    const key =
        (userType === TRADE ? segments.find(segment => TRADE_AD_KEYS.includes(segment)) : '') ||
        segments.find(segment => CONSUMER_AD_KEYS.includes(segment)) ||
        'default';

    if (userType === TRADE && sbTradeAdMap[key]) {
        return sbTradeAdMap[key];
    }
    return sbAdMap[key];
};

export function getProductTileAdData(
    uriRef: string,
    userType: UserType = CONSUMER
): AdProductTile[] {
    const ad = getAdData({ uriRef, userType, adKey: TILE_AD_KEY });
    return ad?.[TILE_AD_KEY] || [];
}

export function getBillboardAdData(
    uriRef: string,
    userType: UserType = CONSUMER
): AdBillboard | null {
    const ad = getAdData({ uriRef, userType, adKey: BILLBOARD_AD_KEY });
    return ad?.[BILLBOARD_AD_KEY] || null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getSkyscraperAdData(uriRef: string): AdSkyscraper | null {
    // we don't use this any more, but there's still some code present
    // this allows for some cleanup
    return null;
}

function getAdCountForPage({
    pageSize = QP_PAGE_SIZE_VALUE_DEFAULT,
    uriRef,
    userType,
}: {
    pageSize: number;
    uriRef: string;
    userType: UserType;
}): number {
    const numberOfTotalTileAds = getProductTileAdData(uriRef, userType)?.length || 0;
    /*
    const numberOfTotalTileAds = ((getAdData({ uriRef, adKey: TILE_AD_KEY, userType }) ||
        []) as AdProductTile[]).length;
        */

    /**
     * if we cant find a limit in our limit map just use the number of ads from the ad map
     * adLimitMap is keyed by page size selected (120, 60) not number of tiles shown (ie 116, 58)
     */
    const adLimit = adLimitMap[pageSize] || numberOfTotalTileAds;
    const numberOfTileAds = numberOfTotalTileAds <= adLimit ? numberOfTotalTileAds : adLimit;

    return numberOfTileAds;
}

export function getNumberOfTiles({
    path,
    pageSize = QP_PAGE_SIZE_VALUE_DEFAULT,
    isSbAdsEnabled = true,
    userType = CONSUMER,
}: {
    path: string;
    pageSize: number;
    isSbAdsEnabled: boolean;
    userType: UserType;
}): number {
    if (isSbAdsEnabled) {
        return pageSize - getAdCountForPage({ uriRef: path, pageSize, userType });
    }
    return pageSize;
}

function getProductTileAdIndex(adIndex: number, pageCol: number = 3, adRow: number = 8): number {
    let slotIndex;
    // (num of columns) * (row to appear in) offset by one
    const initialOffset = adIndex + 1;
    const numCols = pageCol * adRow * initialOffset;
    slotIndex = numCols - 1;

    // ad position offset. Moves the ad from left to right of row
    const offset = pageCol % 2 === 0 ? 0 : adIndex;
    slotIndex -= offset;

    return slotIndex;
}

function shouldGetProductTileAds(numOfTiles: number): boolean {
    if (numOfTiles === QP_PAGE_SIZE_VALUE_DEFAULT || numOfTiles === QP_PAGE_SIZE_VALUE_MAX) {
        return false;
    }

    return true;
}

export const getPageSizeOfTiles = ({
    uriRef,
    numOfTiles,
    userType,
}: {
    uriRef: string;
    numOfTiles: number;
    userType: UserType;
}): number => {
    // tilesToPageSizeMapForPage is indexed by numb of tiles (pageSize - adCount) to pageSize
    // e.g.{ 58: 60, 116: 120 } or { 58: 60, 118: 120 } (for art only because it has 2 ads max) or { 60: 60, 120: 120 }
    const tilesToPageSizeMapForPage = PAGE_SIZE_OPTIONS.reduce<{ [i: number]: number }>(
        (map, { size }) => {
            const adCount = getAdCountForPage({ uriRef, pageSize: size, userType });
            return {
                ...map,
                [size - adCount]: size,
            };
        },
        {}
    );

    return tilesToPageSizeMapForPage[numOfTiles];
};

export const getProductTileAdMap = ({
    uriRef,
    pageCol,
    adRow,
    pageSize: numOfTiles,
    userType,
}: {
    uriRef: string;
    pageCol?: number;
    adRow?: number;
    pageSize: number;
    userType: UserType;
}): { [n: number]: AdProductTile } => {
    if (!numOfTiles || !shouldGetProductTileAds(numOfTiles)) {
        return {};
    }

    const productTileAdData = getProductTileAdData(uriRef, userType);

    const adLimit = getAdCountForPage({
        uriRef,
        pageSize: getPageSizeOfTiles({ uriRef, numOfTiles, userType }), // we need to map numOfTiles back to original pageSize to get correct adLimit
        userType,
    });

    return (
        (productTileAdData || [])
            // limit by number of ads per page size
            .slice(0, adLimit)
            // get index
            .reduce<{ [n: number]: AdProductTile }>((map, data, i) => {
                return {
                    ...map,
                    [getProductTileAdIndex(i, pageCol, adRow)]: data,
                };
            }, {})
    );
};

export const ENABLER_KEY_SB_ADS = 'SB_ADS';
type ShouldEnableSbAdsType = {
    urlSearchParams: URLSearchParams;
    enabler: EnablerAccessor;
};

export async function shouldEnableSbAds({
    enabler,
    urlSearchParams,
}: ShouldEnableSbAdsType): Promise<boolean> {
    let isEnabled = enabler.isEnabledForLocale(ENABLER_KEY_SB_ADS);
    if (urlSearchParams.has(getPerfQp(perfFlags.DISABLE_SB_ADS))) {
        isEnabled = false;
    }
    return isEnabled;
}
