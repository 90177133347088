/**
 * @generated SignedSource<<00fb355cd14c0de759f3718ae0e5cb76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedItemTile_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ItemTileCarousel_itemSearch">;
  readonly " $fragmentType": "SharedItemTile_itemSearch";
};
export type SharedItemTile_itemSearch$key = {
  readonly " $data"?: SharedItemTile_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedItemTile_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemTileCarousel_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "e2aba102253de5a5283b1fccd21c1433";

export default node;
