/**
 * @generated SignedSource<<ac634bb246e28acda46cf5e10b0c74e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSbSharedItemTracking_item$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"ecommerceTracking_item">;
  readonly " $fragmentType": "useSbSharedItemTracking_item";
}>;
export type useSbSharedItemTracking_item$key = ReadonlyArray<{
  readonly " $data"?: useSbSharedItemTracking_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSbSharedItemTracking_item">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useSbSharedItemTracking_item",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "ecommerceTracking_item",
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "ecommerceTrackingProductData_item",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Seller",
              "kind": "LinkedField",
              "name": "seller",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": "displayPriceTracking",
              "args": [
                {
                  "kind": "Variable",
                  "name": "isTrade",
                  "variableName": "isTrade"
                },
                {
                  "kind": "Variable",
                  "name": "page",
                  "variableName": "pageDisplayEnum"
                },
                {
                  "kind": "Variable",
                  "name": "priceBookName",
                  "variableName": "priceBookName"
                },
                {
                  "kind": "Variable",
                  "name": "userCountryCode",
                  "variableName": "userCountryCode"
                }
              ],
              "concreteType": "ItemDisplayPriceType",
              "kind": "LinkedField",
              "name": "displayPrice",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ConvertedAmountList",
                  "kind": "LinkedField",
                  "name": "convertedAmountList",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "currency",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amountType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "textType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "categoryCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "browseUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contemporaryTrackingString",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isNewListing",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "personalizationType",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": [
            {
              "kind": "RootArgument",
              "name": "isTrade"
            },
            {
              "kind": "RootArgument",
              "name": "pageDisplayEnum"
            },
            {
              "kind": "RootArgument",
              "name": "priceBookName"
            },
            {
              "kind": "RootArgument",
              "name": "userCountryCode"
            }
          ]
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "ecommerceTrackingUserProductData_item",
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "shippingCostHelpers_item",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "vertical",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShipmentQuote",
                  "kind": "LinkedField",
                  "name": "shipmentQuotes",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isComplimentary",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isCalculated",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FinancialAmount",
                      "kind": "LinkedField",
                      "name": "totalAmount",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ConvertedAmounts",
                          "kind": "LinkedField",
                          "name": "convertedAmounts",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "USD",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ShipmentServiceMethod",
                      "kind": "LinkedField",
                      "name": "serviceMethod",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Region",
                          "kind": "LinkedField",
                          "name": "region",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "regionName",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "039072b7a6f5100914509f47d883b829";

export default node;
