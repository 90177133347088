/**
 * @generated SignedSource<<a77b1df8ad87d427d1728e132d519381>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ecommerceTrackingUserProductData_item$data = {
  readonly " $fragmentSpreads": FragmentRefs<"shippingCostHelpers_item">;
  readonly " $fragmentType": "ecommerceTrackingUserProductData_item";
};
export type ecommerceTrackingUserProductData_item$key = {
  readonly " $data"?: ecommerceTrackingUserProductData_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ecommerceTrackingUserProductData_item">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ecommerceTrackingUserProductData_item"
};

(node as any).hash = "76cac8d772dd3013ce74d11be487047f";

export default node;
