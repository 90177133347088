import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { TRADE, CONSUMER } from 'dibs-buyer-layout/exports/userTypeEnums';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import { getProductTileAdMap } from '../../utils/adHelpers';
import { useSbSelector } from '../../reducers/useSbSelector';

import {
    type useMergedSbAndSponsoredItems_itemSearch$key,
    type useMergedSbAndSponsoredItems_itemSearch$data,
} from './__generated__/useMergedSbAndSponsoredItems_itemSearch.graphql';

type UseMergedSbAndSponsoredItemsArgs = {
    itemSearch: useMergedSbAndSponsoredItems_itemSearch$key;
    isMobile: boolean;
};

type Sponsored = NonNullable<useMergedSbAndSponsoredItems_itemSearch$data['sponsored']>;
export type SponsoredMetadata = NonNullable<Sponsored['metadata']>;

// S&B item and sponsored item should the the same
export type Item = NonNullable<NonNullable<Sponsored['items']>[number]>;

export type SponsoredEntryMetadataMap = Record<
    string | number,
    Omit<SponsoredMetadata[number], 'itemId' | ' $fragmentType'>
>;

const itemSearchFragment = graphql`
    fragment useMergedSbAndSponsoredItems_itemSearch on ItemSearchQueryConnection {
        displayUriRef
        sponsored {
            items {
                serviceId
                ...SbRespSearchResultContainer_item
                ...SbMobileSearchResultContainer_item
            }
            metadata {
                itemId
                impressionTrackerLink
                clickTrackerLink
            }
        }
        edges {
            node {
                item {
                    serviceId
                    ...SbRespSearchResultContainer_item
                    ...SbMobileSearchResultContainer_item
                }
            }
        }
    }
`;

export const DEFAULT_PAGE_SIZE = 60;
export const MIN_ITEMS_COUNT = 12;
export const RESP_ITEMS_PER_ROW = 3;
export const MOBILE_ITEMS_PER_ROW = 2;
export const SPONSORED_ROW_INDEX_RESP = [0, 5, 10, 16, 21];
export const SPONSORED_ROW_INDEX_MOBILE = [0, 6, 11, 16, 22, 28, 34];

export const useMergedSbAndSponsoredItems = ({
    itemSearch: itemSearchRef,
    isMobile,
}: UseMergedSbAndSponsoredItemsArgs): {
    mergedItems: Item[];
    sponsoredItemsIndexMetadataMap?: SponsoredEntryMetadataMap;
} => {
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const { edges, sponsored, displayUriRef } = itemSearch;
    const { items, metadata } = sponsored || {};

    const { first: pageSize = DEFAULT_PAGE_SIZE, isTrade } = useSbSelector(
        state => state.relayVariables.variables
    );

    const pageSizeNumber = typeof pageSize === 'string' ? parseInt(pageSize) : pageSize;

    const sbItems = useMemo(
        () => (edges || []).map(edge => edge?.node?.item).filter(filterFalsy),
        [edges]
    );
    const sponsoredItems = useMemo(() => (items || []).filter(filterFalsy), [items]);
    const sponsoredMetadata = useMemo(() => (metadata || []).filter(filterFalsy), [metadata]);

    // If there aren't any items, don't attempt merge
    // If item count is less than min needed for sponsored listings, return items
    const areInvalidSbItems =
        !sbItems ||
        (Array.isArray(sbItems) && (sbItems.length === 0 || sbItems.length < MIN_ITEMS_COUNT));

    // If there aren't any sponsored items, return items
    const areInvalidSponsoredItems =
        !sponsoredItems || (Array.isArray(sponsoredItems) && sponsoredItems.length === 0);

    // If we're missing any required arguments or they are invalid, don't attempt merge
    const areInvalidArgs =
        typeof pageSizeNumber !== 'number' ||
        isNaN(pageSizeNumber) ||
        typeof isTrade !== 'boolean' ||
        typeof isMobile !== 'boolean';

    const shouldNotMerge = areInvalidSbItems || areInvalidSponsoredItems || areInvalidArgs;

    const mergedResult = useMemo(() => {
        if (shouldNotMerge) {
            return { mergedItems: sbItems };
        }

        const itemsPerRow = isMobile ? MOBILE_ITEMS_PER_ROW : RESP_ITEMS_PER_ROW;

        const adMap = getProductTileAdMap({
            uriRef: displayUriRef || '',
            pageSize: pageSizeNumber,
            pageCol: itemsPerRow,
            userType: isTrade ? TRADE : CONSUMER,
        });

        const metadataMap = sponsoredMetadata.reduce((acc, value) => {
            if (value && typeof value.itemId === 'string') {
                const { itemId, clickTrackerLink, impressionTrackerLink } = value;
                acc[itemId] = { clickTrackerLink, impressionTrackerLink };
            }

            return acc;
        }, <SponsoredEntryMetadataMap>{});
        const mergedItems = [];

        // We don't count Doubleclick ads on mobile towards the slots because they take up their own row
        let adCount = isMobile ? 0 : Object.entries(adMap).length;

        if (!isMobile) {
            // If ad index is greater than amount of items, decrease ad total count
            for (const prop in adMap) {
                if (Object.hasOwn(adMap, prop)) {
                    const adPos = parseInt(prop);
                    if (adPos >= sbItems.length) {
                        adCount--;
                    }
                }
            }
        }

        const totalSlots = sbItems.length + sponsoredItems.length + adCount;
        const sponsoredRowIndex = isMobile ? SPONSORED_ROW_INDEX_MOBILE : SPONSORED_ROW_INDEX_RESP;

        let adSlotsRemaining = adCount;
        let currRow = 0;
        let sponsoredRow: Item[] = [];
        let sponsoredItemsIndex = 0;
        let itemsIndex = 0;

        const sponsoredItemsIndexMetadataMap: SponsoredEntryMetadataMap = {};

        for (let i = 0; i < totalSlots; i++) {
            if (i !== 0 && i % itemsPerRow === 0) {
                currRow++;
            }

            if (
                itemsIndex === sbItems.length ||
                (sponsoredRowIndex.includes(currRow) && sponsoredItemsIndex < sponsoredItems.length)
            ) {
                const sponsoredItem = sponsoredItems[sponsoredItemsIndex];
                sponsoredRow.push(sponsoredItem);
                sponsoredItemsIndex++;
            } else if (adSlotsRemaining === 0) {
                mergedItems.push(sbItems[itemsIndex]);
                itemsIndex++;
            } else if (!adMap[i + 1]) {
                // adMap is 1-indexed
                mergedItems.push(sbItems[itemsIndex]);
                itemsIndex++;
            } else {
                adSlotsRemaining--;
            }

            if (sponsoredRow.length === itemsPerRow) {
                let counter = 0;
                while (counter < itemsPerRow) {
                    const sponsoredItem = sponsoredRow[counter];
                    const mapIndex =
                        mergedItems.length === 0 ? counter : mergedItems.length + counter;
                    const itemId = sponsoredItem?.serviceId;
                    if (itemId) {
                        sponsoredItemsIndexMetadataMap[mapIndex] = metadataMap[itemId];
                    }

                    counter++;
                }

                mergedItems.push(...sponsoredRow);
                sponsoredRow = [];
            }
        }

        return { mergedItems, sponsoredItemsIndexMetadataMap };
    }, [
        shouldNotMerge,
        displayUriRef,
        isMobile,
        isTrade,
        pageSizeNumber,
        sbItems,
        sponsoredItems,
        sponsoredMetadata,
    ]);

    return mergedResult;
};
